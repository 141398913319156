<template>
  <div>
    <v-col v-if="checkPermission([128])">
      <v-btn @click="dialog = true" color="#e21a1a" class="white--text"><v-icon left>mdi-plus</v-icon>Добавить тег</v-btn>
    </v-col>
    <v-data-table :headers="headers" :items="tags" disable-pagination hide-default-footer class="ma-3">
      <template v-slot:item.nameRu="{ item }">
        <tr>
          <td>
            <v-text-field v-model="item.nameRu" background-color="transparent" flat hide-details="auto" placeholder="Введите наименование" solo />
          </td>
        </tr>
      </template>
      <template v-slot:item.disabledBySuperior="{ item }">
        <tr>
          <td>
            <v-select
              v-model="item.disabledBySuperior"
              :items="isActivePropertyList()"
              background-color="transparent"
              flat
              hide-details="auto"
              placeholder=""
              solo
              color="#e21a1a"
            />
          </td>
        </tr>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn @click="editTagClick(item)" :disabled="itemStatus(item)" color="#e21a1a" text>
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-form ref="form" @submit.prevent="checkInput">
          <v-card-title>Новая категория</v-card-title>
          <v-card-text>
            <v-text-field
              class="my-3"
              v-model="newTag.nameRu"
              :rules="validationRules.notEmpty.concat(validationRules.nameForm)"
              outlined
              dense
              label="Название"
            ></v-text-field>
          </v-card-text>
          <v-card-actions class="d-flex flex-wrap">
            <v-btn class="white--text my-1 ml-auto" type="submit" depressed color="#e21a1a" @click="addTagClick">СОХРАНИТЬ</v-btn>
            <v-btn text class="mx-2 text-none my-1" color="grey darken-3" @click="dialog = false">ОТМЕНА</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <div class="notification">
      <Notification />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ReferenceHelper from '@/helpers/reference/reference.helper';
import Notification from '@/components/Notification';
// import EditTagDialog from '@/components/EditTagDialog';
import Helper from '@/helpers/reference/reference.helper';

export default {
  data() {
    return {
      newTag: {
        nameRu: ''
      },
      completeMessage: '',
      completeDialog: false,
      dialog: false,
      currentPath: this.$route.path,
      validationRules: {
        notEmpty: [(v) => !!v || 0 || 'Обязательное поле'],
        nameForm: [
          (value) => {
            const pattern = /[ЁёА-я,A-Za-z .-]+$/;
            return pattern.test(value) || 'Неверный формат';
          }
        ]
      }
    };
  },
  components: { Notification },
  computed: {
    headers() {
      return [
        { text: 'Название', value: 'nameRu', align: 'start' },
        { text: 'Забанен', value: 'disabledBySuperior' },
        { text: '', value: 'actions', sortable: false }
      ];
    },
    tags() {
      if (!this.tagsList) return [];
      return JSON.parse(JSON.stringify(this.tagsList));
    },
    ...mapGetters('auth', ['userPerm']),
    ...mapGetters('tag', ['tagsList']),
    ...mapGetters('tag', ['createTagResult']),
    ...mapGetters('tag', ['createTagErrorMessage'])
  },
  methods: {
    checkPermission(value) {
      return this.userPerm.find((el) => value.includes(el)) !== undefined;
    },
    checkInput() {
      return true;
    },
    getTagList() {
      this.fetchTagList();
    },
    isActivePropertyList() {
      return ReferenceHelper.getActiveProperty();
    },
    itemStatus(item) {
      let defaultTag = this.tagsList.find((tag) => tag.token === item.token);
      let status = true;
      for (let prop in item) {
        if (item[prop] !== defaultTag[prop]) {
          status = false;
        }
      }
      return status;
    },
    getBooleanValue(value) {
      return Helper.getActiveName(value);
    },
    addTagClick() {
      if (this.$refs.form.validate()) {
        this.addTag(this.newTag)
          .then(() => {
            if (this.createTagResult) {
              this.closeDialog();
              this.showNotification('Категория добавлена', 'success', '2000', 'mdi-check');
            }
          })
          .catch(() => {
            this.showNotification(this.createTagErrorMessage, 'error', '5000', 'mdi-alert-circle-outline');
          });
      }
    },
    editTagClick(item) {
      this.editTag(item).then(() => {
        this.closeDialog();
        this.showNotification('Тег отредактирован', 'success', '2000', 'mdi-check');
        this.getTagList();
      });
    },
    showNotification(message, color, timer, icon) {
      this.showSnack({
        message,
        color,
        timer,
        icon
      });
    },
    closeDialog() {
      this.dialog = false;
      this.newTag.nameRu = '';
    },
    ...mapActions('tag', ['fetchTagList']),
    ...mapActions('tag', ['addTag']),
    ...mapActions('tag', ['editTag']),
    ...mapActions('notification', ['showSnack'])
  },
  mounted() {
    this.getTagList();
  }
};
</script>
<style scoped>
.notification {
  position: fixed;
  bottom: 5px;
  right: 5px;
}
</style>
